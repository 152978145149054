import React from "react";
import { string, bool, shape, arrayOf } from "prop-types";
import { motion } from "framer-motion";
import { isMobileOnly } from "react-device-detect";
import Heading from "../../../Base/HeadingBuilder";
import CtaPrimary from "../../../Base/CtaPrimary";
import AppetizeImage from "../../../../components/ImageQuerys/AppetizeImages";
import useStartMotion from "../../../../hooks/use-start-motion";
import SectionHighlights from "../../SectionHighlights";
import background from "../../../../images/svg/vector-06.svg";

const slideBottomVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: {
    opacity: 1,
    y: 0,
  },
};

const HighlightsHero = ({
  className,
  image,
  title,
  titleSymbol,
  highlights,
  ctaPrimary,
  animations,
}) => {
  const { ref, controls } = useStartMotion();

  return (
    <section
      className={`relative
        max-w-1440 w-full
        ${className}
      `}
      ref={ref}
      data-cy="highlights-hero"
    >
      {/* Image */}
      <motion.div
        className="relative px-4 lg:px-10 -mb-44 lg:-mb-40 z-1"
        animate={controls}
        initial={isMobileOnly || !animations ? "visible" : "hidden"}
        variants={slideBottomVariants}
        transition={{ delay: 0.1, duration: 0.8 }}
      >
        {image.mobileImageName && (
          <AppetizeImage
            className="rounded-large md:hidden image-mobile"
            imageName={image.mobileImageName}
          />
        )}

        <AppetizeImage
          className="rounded-large hidden md:block image-desktop"
          imageName={image.imageName}
        />

        {image.title && (
          <Heading
            className={`
          absolute text-white
          max-w-70-percent left-8 bottom-11
          md:left-16
          md:max-w-sm
          xl:left-40 
          `}
            level={2}
            injectionType={3}
            withBlueSymbol
          >
            {image.title}
          </Heading>
        )}
        {image.subtext && (
          <div
            className={`
          absolute text-white transform -rotate-90
          top-28 -right-8
          md:right-0
          lg:top-auto lg:bottom-28
          xl:right-20
          `}
          >
            {image.subtext}
          </div>
        )}
      </motion.div>

      {/* Content */}
      <div
        className={`
        relative bg-black w-full rounded-large
        pt-72 lg:pt-80
        px-4 md:px-16 xl:px-40 
        pb-20 lg:pb-40
        `}
        // animate={controls}
        // initial={isMobileOnly || !animations ? "visible" : "hidden"}
        // variants={fadeInVariants}
        // transition={{ delay: 0.4, duration: 0.8 }}
      >
        <img
          className="absolute -top-12 right-0 transform lg:scale-200"
          src={background}
          alt="background"
        />
        <div
          className={`
          mb-20 lg:mb-0
          flex flex-col lg:flex-row
          lg:items-end lg:justify-between
          `}
        >
          <Heading
            className="text-white lg:max-w-80-percent"
            withBlueSymbol
            level={2}
            injectionType={1}
            symbol={titleSymbol}
          >
            {title}
          </Heading>

          <CtaPrimary
            className="relative mt-2 lg:mt-0 mb- lg:mb-8"
            title={ctaPrimary.title}
            target={ctaPrimary.target}
          />
        </div>

        <SectionHighlights
          highlights={highlights}
          animations={animations}
          withWhiteText
        />
      </div>
    </section>
  );
};

HighlightsHero.propTypes = {
  className: string,
  image: shape({
    imageName: string,
    mobileImageName: string,
    title: string,
    subtext: string,
  }).isRequired,
  title: string.isRequired,
  titleSymbol: string,
  highlights: arrayOf(
    shape({
      title: string,
      subtext: string,
    })
  ).isRequired,
  ctaPrimary: shape({
    title: string,
    target: string,
  }),
  animations: bool,
};

HighlightsHero.defaultProps = {
  className: "",
  titleSymbol: ".",
  ctaPrimary: null,
  animations: true,
};

export default HighlightsHero;
